import React from 'react';
import '../style/major-btn-style.css';
import CustomButton from './btn-interface';

const SECONDARY_BTN_CSS_CLASS = 'medbiz__button-major';

const MajorButton = ({children, className, style, onClick, to, pdf}) => (
  <CustomButton
    btnTypeCssClass={SECONDARY_BTN_CSS_CLASS}
    className={className}
    style={style}
    onClick={onClick}
    to={to}
    pdf={pdf}
  >
    { children }
  </CustomButton>
);

export default MajorButton;
