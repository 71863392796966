import * as React from "react"
import { StaticImage } from 'gatsby-plugin-image';
import MajorButton from '../../components/buttons/components/major-btn';
import './style.css';
import Footer from '../../components/layout/components/footer';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Seo from '../../components/seo';
import ogImage from '../../images/general/404_grafika.png';

const NotFoundPage = () => {

  const {t} = useTranslation();

  return (
    <>

      <Seo
        title="Not Found"
        description="Naszym zadaniem jest łączenie studentów z kierunków technologicznych, biznesowych, naukowych, a także medycznych, dając im możliwość rozwoju i komercjalizacji swoich innowacyjnych pomysłów."
        ogImage={ogImage}
      />

      <main className="container not-found-ctn">

        <div className="left-div">
          <StaticImage src="../../images/header/dark_logo.png" alt="MedBiz czarne logo"/>
          <h2>{t`not-found.title`}</h2>
          <p>{t`not-found.text`}</p>
          <MajorButton to="/">
            {t`not-found.btn-text`}
          </MajorButton>
        </div>
        <div className="right-div"/>
        <StaticImage className="img404" src="../../images/general/404_grafika.png" alt="Not found error 404"/>

      </main>

      <Footer/>
    </>
  )
}

export default NotFoundPage
