import React from 'react';
import '../style/major-btn-style.css';
import { Link } from 'gatsby-plugin-react-i18next';

const CustomButton = ({btnTypeCssClass, children, className, style, onClick, to, pdf=false}) => {

  if (pdf===true){
    return (
      <a
        href={to}
        className={`${btnTypeCssClass} ${className}`}
        style={style}
      >
        {children}
      </a>
    )
  }

  return (
    <>
      {
        to === undefined ? (
          <button
            className={`${btnTypeCssClass} ${className}`}
            style={style}
            onClick={onClick}
          >
            { children }
          </button>
        ) : (
          <Link
            to={to}
            className={`${btnTypeCssClass} ${className}`}
            style={style}
          >
            { children }
          </Link>
        )
      }
    </>

  );
}

export default CustomButton;
